<template>
<diV>
  <b-card-code title="Impressão Etiqueta Por OP">
    <b-row>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Filtrar Por:"
        >
          <b-form-select
          v-model="FiltroTela.filtrarPorSelected"
          :options="filtrarPorOptions">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
          label="Valor:"
          
        >
          <b-form-input
            id="filtrarPorValue"
            placeholder=""
            v-model="FiltroTela.filtrarPorValue"
            required
          />
        </b-form-group>
      </b-col>

    <b-col
        md="6"
        xl="4"
        class="mb-1"
    >
    <br>
    <div class="DivBotaoLadoLado" >
     <b-button  variant="primary" 
      type="button" @click="Consultar"><span>BUSCAR</span></b-button>
    </div>
    </b-col>
    </b-row>
    
</b-card-code>

<div>
<b-card-code style="min-height: 300px;" >
  <b-table
      striped
      hover
      responsive
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
      style="min-height: 300px;"
    >
    <template #cell(idiproc)="row">
             
        <b-button size="sm"
         style="width: 40px;"
          variant="warning"
          @click="MostraEtiquetaOP(row.item)">
          I
        </b-button>

      
      </template>
      <template #cell(qtdetiqueta)="row">
      
          <b-form-input
            id="qtdetiqueta"
            v-model="row.item.qtdetiqueta"
            required
            class="font-size10"
            style="width: 70px;"
            autocomplete="off"
          />
          
      </template>

      <template #cell(codvol)="row">

          <multiselect 
              v-model="CODVOL"
              placeholder="Selecione"
              label="name"
              track-by="code"
              :options="CODVOLOption"
              :multiple="false"
              :taggable="false"
              :close-on-select="true"
              :option-height="50"
              :show-labels="false"
              class="font-size10"
              open-direction="bottom"
            ></multiselect>
      </template>
      
      <template #cell(qtdetiquetaimp)="row">
      <b-col>
          <b-form-input
            id="qtdetiquetaimp"
            v-model="row.item.qtdetiquetaimp"
            disabled
            class="font-size11"
          />

        </b-col>
      
      </template>


    </b-table>

</b-card-code>
</div>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import BCardCode from '@core/components/b-card-code'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import FiltrosPortal from '@/customPortal/FiltrosPortal.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import Spinner from '@/components/Spinner.vue'


import {
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    Multiselect,
    BFormInput,
    BCardCode,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BFormInput,
    BPagination,
    BCardBody,
    BCol,
    BButton
    
  },
  data() {
    return {
      FiltroTela: {
        TitloTela: 'EtiquetaOP',
        Selecionado: false,
        filtrarPorValue: '',
        filtrarPorSelected: 'IDIPROC',
        FiltroComplementar: [],
      },
      filtrarPorOptions: [
        { value: 'IDIPROC', text: 'Nº Apontamento' },
        { value: 'REFERENCIA', text: 'Cod. Prod. Alternativo' },
      ],
      perPage: 10,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'idiproc', label: 'AÇÕES'  },
        { key: 'referencia', label: 'Cod.Alt.', sortable: true, tdClass: 'td10' },
        { key: 'qtdetiqueta', label: 'Qtd.Etiqueta', sortable: false, tdClass: 'td10' },
        { key: 'codvol', label: 'UND', sortable: false },
        { key: 'qtdetiquetaimp', label: 'Qtd.Impressas', sortable: false },
        { key: 'descrprod', label: 'Produto', sortable: false,tdClass: 'td250' },
        { key: 'dtfabricacao', label: 'Fabricação', sortable: false,tdClass: 'td160' },
        { key: 'dtval', label: 'Validade', sortable: false,tdClass: 'td160' },
        { key: 'controle', label: 'Lote', sortable: false, tdClass: 'td50'  },
        { key: 'ean', label: 'EAN', sortable: false },
        { key: 'dum', label: 'DUM', sortable: false },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      CODVOL: { "name": "CX", "code": "CX" },
      CODVOLOption: [], 
    }
  },
  mounted() {

    this.ConsultarOpcoes()

    // Seta q quantidade de Linhas no Grid
    this.totalRows = this.items.length
    // Consulta Dados para Filtro na Tela
    this.DescarregarFiltroTela()
  },
  methods: {
    async Consultar() {
      Spinner.StarLoad()
      let notification = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/skEtiqueta/ConsultaEtiquetaOP?CODUSU=${usuario.codusu}`
      
      this.FiltroTela.FiltroComplementar = []
      
      this.CarregaFiltroTela()

      const ParametroJson = JSON.parse(JSON.stringify(this.FiltroTela))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.items = response.data
          this.totalRows = this.items.length
        } else {
          notification = ConexaoAPI.MsgFiltroSemRetorno()
          AlertaMsg.FormataMsg(notification)
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    RedirecionaDetalhe(id, rota) {
      const $rota = `${rota}/${id}`
      this.$router.push($rota)
    },
    CarregaFiltroTela() {
      const usuario = this.$session.get('usuario')
      usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
      this.$session.set('usuario', usuario)
    },
    DescarregarFiltroTela() {
      const usuario = this.$session.get('usuario')
      const Filtrado = FiltrosPortal.RetornaFiltro(usuario, this.FiltroTela.TitloTela)
      if (Filtrado.Selecionado) {
        // Realiza Validacao de Campo da Tela para Executar Realmente o Filtro
        if (Filtrado.filtrarPorValue !== '') {
          this.FiltroTela = Filtrado// Carrega o Filtro
          
          // Descarrega Filtro Complementar
          /*this.FiltroTela.FiltroComplementar.forEach(ftComplementar => {
            switch (ftComplementar.parametro) {
            }          
          })*/

          this.Consultar() // Executa o Filtro
        }
      }
    },
    async MostraEtiquetaOP(etiqueta) {
      Spinner.StarLoad()
      let notification = ''
      const IDIPROC = etiqueta.idiproc
      const QTDETIQUETA = etiqueta.qtdetiqueta
      let CODVOLETIQUETA = ''

      if(this.CODVOL != null){
        if(Array.isArray(this.CODVOL) == false)
        {
          CODVOLETIQUETA = this.CODVOL.code
        }
      }


      const usuario = this.$session.get('usuario')
      const URLpost = `/skEtiqueta/MostraEtiquetaOP?CODUSU=${usuario.codusu}&IDIPROC=${IDIPROC}&QTDETIQUETA=${QTDETIQUETA}&CODVOLETIQUETA=${CODVOLETIQUETA}`
      await apiPortalSky.post(URLpost, IDIPROC).then(response => {
        if (response.status === 200) {
          const Documento = response.data
          const NomeDoc = Documento[0].descrprod + " - OP " + Documento[0].idiproc.toString() + " - " + CODVOLETIQUETA
          const Mensagem = Documento[0].mensagem.toString() 

          if(Mensagem === ''){
            this.downloadText(NomeDoc, Documento[0].etiqueta)
          } else{
            notification = { message: Mensagem, alert_type: 'attention' }
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    downloadText(nome, text) {
      const blob = new Blob([text], { type: 'text/plain' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${nome}.prn`
      link.click()
    },
    async ConsultarOpcoes() {
            let notification = ""
            Spinner.StarLoad()
            const usuario = this.$session.get('usuario')
            
            let ParametroJson = ""
            let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=UnidadeMedidaRotulo`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                if (response.data.length > 0) {
                    this.CODVOLOption = response.data
                } else {
                    notification = ConexaoAPI.MsgFiltroSemRetorno()
                    AlertaMsg.FormataMsg(notification)
                }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
            Spinner.EndLoad()
            }
        },
  },
  computed: {
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>